import { useState, useMemo, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';
import { isDate } from 'validator';

// Our components
import Alert from 'components/Alert';
import Dropdown from 'components/Dropdown/Dropdown';
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Input from 'components/Input/TextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import RateInput from 'components/Input/RateInput';
import SoraTextField from 'components/Input/SoraTextField';
import { TEXT, NUMBER } from 'components/Input/Types';
import RequiredFieldsTooltip from 'components/Forms/NewLoanForms/RequiredFieldsTooltip';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our hooks 🪝
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Create Deal Hook
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';

// Utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Constants
import { US_STATE_OPTIONS, AUTO, REFINANCE_OFFER_TYPE } from 'shared/constants';
import YearDatePicker from 'components/DatePicker/YearDatePicker';

const VEHICLE_CONDITION_OPTIONS = ['Excellent', 'Good', 'Fair', 'Poor'];

function AutoRefiForm({
	isClientNameProvided,
	refinancingTradelineId,
	additionalNotesValue,
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	clientId,
	conditionValue,
	currentLoanRemainingTermValue,
	currentLoanInterestRateValue,
	creditScore,
	dateOfBirth,
	emailAddressValue,
	firstName,
	lastName,
	loanAmountValue,
	loanRequestId,
	loanType,
	mileage,
	postSuccessEvent,
	stateValue,
	vinValue,
	yearOfVehicleValue
}) {
	const queryClient = useQueryClient();
	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Mutations
	const updateLoanInquiry = useMutateUpdateLoanInquiry();
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	const [fullName, setFullName] = useState(fullNameValue || '');
	const [state, setState] = useState(stateValue || '');
	const [currentInterestRate, setCurrentInterestRate] = useState(
		currentLoanInterestRateValue || ''
	);
	const [tradelineId, setTradelineId] = useState('');
	const [currentRemainingTerm, setCurrentRemainingTerm] = useState(
		currentLoanRemainingTermValue || ''
	);
	const [loanAmount, setLoanAmount] = useState(loanAmountValue || '');
	const [dob, setDOB] = useState(dateOfBirth || '');
	const [vehicleVINNumber, setVehicleVINNumber] = useState(vinValue || '');
	const [vehicleMileage, setVehicleMileage] = useState(mileage || '1');
	const [vehicleCondition, setVehicleCondition] = useState(
		conditionValue || 'Good'
	);
	const [yearOfVehicle, setYearOfVehicle] = useState(
		yearOfVehicleValue || new Date()
	);

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const formData = [
		isClientNameProvided,
		loanAmount,
		currentRemainingTerm,
		currentInterestRate
	];

	const loanRequestDetailsRequiredFields = [
		loanAmount,
		currentInterestRate,
		currentRemainingTerm,
		state,
		vehicleVINNumber,
		mileage
	];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(
				atLoanRequestDetailsUrl
					? loanRequestDetailsRequiredFields
					: formData
			) && isClientNameProvided,
		atLoanRequestDetailsUrl ? loanRequestDetailsRequiredFields : formData
	);

	useEffect(() => {
		if (conditionValue) {
			setVehicleCondition(conditionValue);
		}
		if (vinValue) {
			setVehicleVINNumber(vinValue);
		}
		if (mileage) {
			setVehicleMileage(mileage);
		}
		if (refinancingTradelineId) {
			setTradelineId(refinancingTradelineId);
		}
	}, [vinValue, mileage, conditionValue, refinancingTradelineId]);

	useEffect(
		() =>
			setMessage(`<pre>
			The advisor ${advisorName} has request an Automotive Loan Refinance for one of their clients.

			Advisor making request:
			Advisor's name: ${advisorName}
			Advisor's email: ${advisorEmail}
			
			They made a request for an Automotive Loan Refinance for the following CLIENT:

			Client's name: ${fullName}
			Client's Email address: ${emailAddress}
			Client's date of birth: ${dob}
			Client's requested loan amount: ${loanAmount}
			State of vehicle purchase: ${state}
			Vehicle VIN number: ${vehicleVINNumber}
			Vehicle estimated mileage: ${vehicleMileage}
			Vehicle condition: ${vehicleCondition}
			Year of vehicle: ${yearOfVehicle}

			Client's current loan remaining balance: ${currentRemainingTerm}
			Client's current loan remaining interest rate: ${currentInterestRate}

			Additional Notes:

			${additionalNotes}
			</pre>
		`),
		[
			...formData,
			additionalNotes,
			currentRemainingTerm,
			currentInterestRate,
			dob,
			emailAddress,
			loanAmount,
			state,
			vehicleCondition,
			vehicleMileage,
			vehicleVINNumber,
			yearOfVehicle
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];
		const subject = `Automotive Refinance Loan Request - ${firstName} ${lastName}`;
		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setDOB,
						setEmailAddress,
						setCurrentInterestRate,
						setCurrentRemainingTerm,
						setFullName,
						setLoanAmount,
						setState,
						setVehicleCondition,
						setVehicleMileage,
						setVehicleVINNumber,
						setYearOfVehicle
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			clientId,
			dob,
			emailAddress,
			currentRemainingTerm,
			currentInterestRate,
			firstName,
			lastName,
			loanAmount,
			offerType: REFINANCE_OFFER_TYPE,
			state,
			tradelineType: AUTO,
			tradelineId,
			vehicleCondition,
			vehicleMileage,
			vehicleVINNumber,
			yearOfVehicle
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			clientId,
			dob,
			emailAddress,
			firstName,
			currentRemainingTerm,
			currentInterestRate,
			lastName,
			loanAmount,
			offerType: REFINANCE_OFFER_TYPE,
			state,
			tradelineType: AUTO,
			vehicleCondition,
			vehicleMileage,
			vehicleVINNumber,
			yearOfVehicle
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setFullName,
						setAdditionalNotes,
						setDOB,
						setEmailAddress,
						setCurrentInterestRate,
						setCurrentRemainingTerm,
						setFullName,
						setLoanAmount,
						setState,
						setVehicleCondition,
						setVehicleMileage,
						setVehicleVINNumber,
						setYearOfVehicle
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const isValidDate = useMemo(() => {
		const isStringDate = typeof yearOfVehicle === 'string';
		const isDateType = yearOfVehicle instanceof Date;
		if (isStringDate && yearOfVehicle !== '') {
			const isDateCheckWithConversion = isDate(new Date(yearOfVehicle));
			return isDateCheckWithConversion;
		}

		if (isDateType) {
			return isDate(yearOfVehicle);
		}
		// if yearOfVehicle is null this returns false;
		return false;
	}, [yearOfVehicle]);

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'percent',
				value: currentInterestRate,
				label: 'Current loan annual interest rate:'
			},
			{
				type: 'currency',
				value: loanAmount,
				label: 'Current outstanding loan balance:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				${formattedCollection}
				Remaining Term: ${currentRemainingTerm === '' ? 'N/A' : currentRemainingTerm}
				State: ${state}
				Vehicle VIN: ${vehicleVINNumber}
				Vehicle Mileage: ${vehicleMileage}
				Vehicle Condition: ${vehicleCondition}
				Year of Vehicle: ${yearOfVehicle === '' ? 'N/A' : yearOfVehicle}
				------------------------------------------------------------------------------------------------
				Advisor's notes: ${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: loanAmount,
				dealname: `${advisorName} / ${fullName}`,
				dealtype: loanType,
				borrower: fullName,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});
		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
			sx={{ height: '100%', width: '100%' }}
		>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						gutterBottom
						component="div"
						sx={{
							marginTop: 6
						}}
					>
						Automotive Loan Refinance
					</Typography>

					<Typography
						variant="body2"
						gutterBottom
						component="div"
						sx={{
							marginBottom: 4
						}}
					>
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<Grid container spacing={2}>
					{sendFormDataError && <Alert variant="error" />}

					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />

					<Grid item xs={12} md={6}>
						{/* Desired Loan Amount */}
						<CurrencyTextInput
							label="Current outstanding loan balance *"
							value={loanAmount}
							onChange={setLoanAmount}
							type={NUMBER}
							inputProps={{
								'data-test': 'loanAmount'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Outstanding Mortgage Interest Rate */}
						<RateInput
							rate={currentInterestRate}
							setRate={setCurrentInterestRate}
							disabled={atLoanRequestDetailsUrl}
							label="Current loan annual interest rate *"
							inputProps={{
								'data-test': 'currentLoanAnnualInterestRate'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Input
							type={TEXT}
							label="Remaining term *"
							value={currentRemainingTerm}
							onChange={setCurrentRemainingTerm}
							inputProps={{
								'data-test': 'currentRemainingTerm'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* State */}
						<Dropdown
							dataTestTag="state"
							variant="outlined"
							label={
								atLoanRequestDetailsUrl ? 'State *' : 'State'
							}
							items={US_STATE_OPTIONS}
							selected={state}
							onChange={setState}
							placeholder="State"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Input
							type={TEXT}
							label={
								atLoanRequestDetailsUrl
									? 'Vehicle VIN or license plate number *'
									: 'Vehicle VIN or license plate number'
							}
							value={vehicleVINNumber}
							onChange={setVehicleVINNumber}
							inputProps={{
								'data-test': 'vinNumber'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Input
							type={NUMBER}
							label={
								atLoanRequestDetailsUrl
									? 'Vehicle mileage *'
									: 'Vehicle mileage'
							}
							subLabel="Estimate is fine"
							value={vehicleMileage}
							onChange={setVehicleMileage}
							inputProps={{
								'data-test': 'mileage'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Dropdown
							dataTestTag="condition"
							items={VEHICLE_CONDITION_OPTIONS}
							selected={vehicleCondition}
							onChange={setVehicleCondition}
							variant="outlined"
							label="Vehicle condition"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<YearDatePicker
							id="yearOfVehicle"
							label="Vehicle year"
							onChange={setYearOfVehicle}
							value={yearOfVehicle}
							error={yearOfVehicle === null || !isValidDate}
							errorText="Year of vehicle needs to be a valid date"
							inputProps={{
								'data-test': 'yearOfVehicle'
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
						/>
					</Grid>
				</Grid>
			)}

			<Grid item xs={12} marginTop={4} marginBottom={10}>
				<RequiredFieldsTooltip>
					<span>
						<PrimaryButton
							disabled={!isFormReady}
							onClick={submitForm}
						>
							Submit
						</PrimaryButton>
					</span>
				</RequiredFieldsTooltip>
			</Grid>
		</Box>
	);
}

export default AutoRefiForm;
