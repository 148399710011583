import { Grid, Typography } from '@mui/material';
import { useState, useMemo } from 'react';
import { useQueryClient } from 'react-query';

// Our Components
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import SoraTextField from 'components/Input/SoraTextField';
import RequiredFieldsTooltip from 'components/Forms/NewLoanForms/RequiredFieldsTooltip';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Our utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our Constants
import { NUMBER } from 'components/Input/Types';
import { SBLOC_TYPE } from 'shared/constants/LoanInquiry/LoanInquiryTypes';

function SblocLoanForm({
	isClientNameProvided,
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	additionalNotesValue,
	clientId,
	creditScoreValue,
	firstName,
	lastName,
	loanAmountValue,
	loanRequestId
}) {
	const queryClient = useQueryClient();
	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const createDeal = useMutateCreateDeal();
	const sendGenericEmail = useMutateSendGenericEmail();
	const updateLoanRequest = useMutateUpdateLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	// Pre-populated state items
	const [creditScore, setCreditScore] = useState(creditScoreValue || '');
	const [totalValueInvestableAssets, setTotalValueInvestableAssets] =
		useState('');
	const [totalValueTaxableAssets, setTotalValueTaxableAssets] = useState('');
	const [loanAmount, setLoanAmount] = useState(loanAmountValue || '');

	// Other fields
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);

	// Modal
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	// Required Fields for Other Loan Form
	const loanRequestDetailsRequiredFields = [loanAmount];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(loanRequestDetailsRequiredFields) &&
			isClientNameProvided,
		loanRequestDetailsRequiredFields
	);

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			clientId,
			creditScore,
			firstName,
			loanAmount,
			offerType: 'OTHER',
			tradelineType: SBLOC_TYPE,
			investableAssetsValue: +totalValueInvestableAssets,
			taxableAccountAssetsValue: +totalValueTaxableAssets,
			desiredLoanAmount: +loanAmount
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];

		const subject = 'Securities Based Line Request';

		const message = `<pre>
			The advisor has priced a Securities Based Line for one of their clients 
			
			Advisor making request:
			Advisor's name: ${advisorName}
			Advisor's email: ${advisorEmail}
			
			They made a request for the following CLIENT:

			Client's name: ${fullNameValue}
			Requested loan amount: ${loanAmount}
			Client's Credit score: ${creditScore}

			Additional Notes:

			${additionalNotes}</pre>
		`;

		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setCreditScore,
						setLoanAmount
					]);
				}
			}
		);
	};

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: loanAmount,
				label: 'Desired loan amount:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				${formattedCollection}
				------------------------------------------------------------------------------------------------
				Advisor's notes: ${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: loanAmount,
				dealname: `${advisorName} / ${fullNameValue}`,
				dealtype: 'SBLOC',
				borrower: fullNameValue,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});

		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	return (
		<Grid container spacing={2}>
			{savingLoanInquiry && <Loader />}

			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						gutterBottom
						component="div"
						sx={{
							marginTop: 6
						}}
					>
						Securities Based Line
					</Typography>

					<Typography
						variant="body2"
						gutterBottom
						component="div"
						sx={{
							marginBottom: 4
						}}
					>
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{!savingLoanInquiry && (
				<>
					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />
					<Grid item xs={12} md={6}>
						<CurrencyTextInput
							label="Total value of Investable Assets"
							value={totalValueInvestableAssets}
							onChange={setTotalValueInvestableAssets}
							type={NUMBER}
							inputProps={{
								'data-test': 'totalValueInvestableAssets'
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<CurrencyTextInput
							label="Total Value of Assets in a Taxable Account (Non-Retirement)"
							value={totalValueTaxableAssets}
							onChange={setTotalValueTaxableAssets}
							type={NUMBER}
							inputProps={{
								'data-test': 'totalValueTaxableAssets'
							}}
						/>
					</Grid>{' '}
					<Grid item xs={12} md={6}>
						<CurrencyTextInput
							label="Desired Loan Amount *"
							value={loanAmount}
							onChange={setLoanAmount}
							type={NUMBER}
							inputProps={{
								'data-test': 'loanAmount'
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
						/>
					</Grid>
					<Grid item xs={12} marginTop={4} marginBottom={6}>
						<RequiredFieldsTooltip>
							<span>
								<PrimaryButton
									disabled={!isFormReady}
									onClick={submitForm}
								>
									Submit
								</PrimaryButton>
							</span>
						</RequiredFieldsTooltip>
					</Grid>
				</>
			)}
		</Grid>
	);
}

export default SblocLoanForm;
