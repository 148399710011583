import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';

// Our Components
import AnalyticsTable from 'components/Table/AnalyticsTable';
import Dropdown from 'components/Dropdown/Dropdown';
import LoanComparisonTabs from 'components/Analytics/LoanComparisonTabs';
import { TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useGetClients from 'hooks/useGetClients';
import useGetClientLiabilitiesAnalytics from 'hooks/analytics/useGetClientLiabilitiesForAnalytics';
import useMutateGetAnalyticsOffer from 'hooks/analytics/useMutateGetAnalyticsOffer';
import useMutateGetAmortizationTable from 'hooks/analytics/useMutateGetAmortizationTable';
import useMutateGetAnalyticsSavings from 'hooks/analytics/useMutateGetAnalyticsInterestSavings';
import useMutateGetAnalyticsNPV from 'hooks/analytics/useMutateGetAnalyticsNPV';
import useMutateGetAnalyticsUpsidePotential from 'hooks/analytics/useMutateGetAnalyticsUpsidePotential';
import useQueryGetAssumptions from 'hooks/analytics/assumptions/useQueryGetAssumptions';

// Our utils
import { dataIsValid, roundToDecimal } from 'shared/utils';

// our constants
import { MORTGAGE } from 'shared/constants';

// Local Constants
const DEFAULT_RATE = '6';

const createLiabilityLabels = (clientLiabilities) => {
	const isValidLiabilities =
		Array.isArray(clientLiabilities) && clientLiabilities.length > 0;

	if (isValidLiabilities) {
		return clientLiabilities.map(
			({ lender, tradelineId }) => `${lender} - ${tradelineId}`
		);
	}

	return ['No Liabilities Found'];
};

const stripTradelineId = (liabilityLabel) => {
	const isValidLabel =
		liabilityLabel !== '' && liabilityLabel !== 'No Liabilities Found';

	if (isValidLabel) {
		const splitLabel = liabilityLabel.split(' ');

		const tradeLineId = splitLabel[splitLabel.length - 1];

		return tradeLineId;
	}

	return null;
};

function LoanComparison() {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	// Currently selected Tab
	const [tabPanelSelected, setTabPanelSelected] = useState(0);

	// Client State
	const [selectedLiabilityData, setSelectedLiabilityData] = useState('');
	const selectedLiabilityDataRef = useRef(null);

	// Analytics Table State
	const [bestOfferBalance, setBestOfferBalance] = useState('');
	const [bestOfferInterestRate, setBestOfferInterestRate] =
		useState(DEFAULT_RATE);
	const [bestOfferTenure, setBestOfferTenure] = useState('');

	const { isLoading, isSuccess, data } = useGetClients('');

	const { state } = useLocation();

	const prePopulatedClientData = state?.clientSelected;

	const isSomeClientSelected = prePopulatedClientData ?? false;

	const { data: assumptionsData, isSuccess: isSuccessAssumptions } =
		useQueryGetAssumptions(prePopulatedClientData?.id);

	const enabledGatherLiabilitiesCall =
		isSuccess && dataIsValid(data) && !!isSomeClientSelected;

	const analyticsOffer = useMutateGetAnalyticsOffer();
	const analyticsAmortization = useMutateGetAmortizationTable();
	const analyticsSavings = useMutateGetAnalyticsSavings();
	const analyticsNPV = useMutateGetAnalyticsNPV();
	const analyticsUpsidePotential = useMutateGetAnalyticsUpsidePotential();

	const {
		isLoading: isAnalyticsOfferLoading,
		isSuccess: isAnalyticsOfferSuccess,
		data: analyticsOfferTableData
	} = analyticsOffer;

	const { data: analyticsAmortizationData } = analyticsAmortization;

	const {
		isLoading: isAnalyticsSavingLoading,
		isSuccess: isAnalyticsSavingSuccess,
		data: analyticsSavingData
	} = analyticsSavings;

	const {
		isLoading: isAnalyticsNPVLoading,
		isSuccess: isAnalyticsNPVSuccess,
		data: analyticsNPVData
	} = analyticsNPV;

	const {
		isLoading: isAnalyticsUpsidePotentialLoading,
		isSuccess: isAnalyticsUpsidePotentialSuccess,
		data: analyticsUpsidePotentialData
	} = analyticsUpsidePotential;

	const {
		isLoading: clientLiabilitiesIsLoading,
		data: clientLiabilitiesData,
		isSuccess: clientLiabilitiesIsSuccess
	} = useGetClientLiabilitiesAnalytics(
		prePopulatedClientData?.id ?? 0,
		enabledGatherLiabilitiesCall,
		(clientWalletOverviewData) => {
			const clientLiabilities = clientWalletOverviewData;

			if (dataIsValid(clientLiabilities))
				return clientLiabilities.filter(
					(currentClientLiability) =>
						currentClientLiability.tradeLineType === MORTGAGE
				);

			return [];
		}
	);

	const isLiabilityDataEmpty =
		clientLiabilitiesIsSuccess &&
		Array.isArray(clientLiabilitiesData) &&
		clientLiabilitiesData.length < 1;

	const clearAllPageData = (clearSelectedLiabilityData = true) => {
		if (clearSelectedLiabilityData) {
			setSelectedLiabilityData('');
		}
		setBestOfferBalance('');
		setBestOfferInterestRate('');
		setBestOfferTenure('');
		analyticsOffer.reset();
		analyticsAmortization.reset();
		analyticsSavings.reset();
		analyticsNPV.reset();
		analyticsUpsidePotential.reset();
	};

	useEffect(() => {
		clearAllPageData();
	}, [prePopulatedClientData, clientLiabilitiesData, assumptionsData]);

	useEffect(() => {
		const isSelectedLiabilityDataChanged =
			selectedLiabilityDataRef.current !== undefined &&
			selectedLiabilityData !== '' &&
			selectedLiabilityDataRef.current !== '' &&
			selectedLiabilityDataRef.current !== selectedLiabilityData;

		if (isSelectedLiabilityDataChanged) {
			clearAllPageData(false);
		}

		// Update the ref with the new value
		selectedLiabilityDataRef.current = selectedLiabilityData;
	}, [selectedLiabilityData]);

	useEffect(() => {
		if (
			isSuccessAssumptions &&
			assumptionsData &&
			assumptionsData.assets[0]
		) {
			setBestOfferInterestRate(
				roundToDecimal(assumptionsData.assets[0].firstGrowth)
			);
		}
	}, [assumptionsData, isSuccessAssumptions]);

	const isPageReady =
		isSomeClientSelected &&
		!clientLiabilitiesIsLoading &&
		selectedLiabilityData !== '';

	const currentlySelectedClientLiabilitiesLabels = createLiabilityLabels(
		clientLiabilitiesData
	);

	const requestOffer = () => {
		const liabilityId = stripTradelineId(selectedLiabilityData);

		const createAssumptionArgs = () => {
			const isAssumptionOffer =
				bestOfferBalance !== '' &&
				bestOfferInterestRate !== '' &&
				bestOfferTenure !== '';
			if (isAssumptionOffer)
				return {
					assumptionOffer: {
						balance: bestOfferBalance,
						rate: bestOfferInterestRate,
						termInMonth: +bestOfferTenure * 12
					}
				};
			return {};
		};

		const additionalArgs = createAssumptionArgs();

		const analyticsRequestPayload = {
			liabilityId,
			expectedAnnualReturn:
				assumptionsData && assumptionsData.assets[0]
					? assumptionsData.assets[0].firstGrowth
					: DEFAULT_RATE,
			...additionalArgs
		};

		analyticsOffer.mutate(analyticsRequestPayload, {
			onSuccess: (analyticsOfferData) => {
				const { bestOffer } = analyticsOfferData;

				const {
					balance: analyticsOfferBalance,
					interestRate: analyticsOfferInterestRate,
					remainingMonth: analyticsOfferTenure
				} = bestOffer;

				setBestOfferInterestRate(
					roundToDecimal(analyticsOfferInterestRate)
				);

				setBestOfferBalance(analyticsOfferBalance);
				// setBestOfferInterestRate(
				// 	roundToDecimal(analyticsOfferInterestRate)
				// );
				setBestOfferTenure(analyticsOfferTenure / 12);
			}
		});

		analyticsAmortization.mutate(analyticsRequestPayload);

		analyticsSavings.mutate(analyticsRequestPayload);

		analyticsNPV.mutate(analyticsRequestPayload);

		analyticsUpsidePotential.mutate(analyticsRequestPayload);
	};

	if (isLoading) {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Skeleton
						variant="rectangular"
						height={350}
						sx={{ marginBottom: 4 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Skeleton
						variant="rectangular"
						height={350}
						sx={{ marginBottom: 4 }}
					/>
				</Grid>
			</Grid>
		);
	}

	if (isSuccess) {
		return (
			<>
				<Box
					sx={{
						minHeight: 450,
						backgroundColor: 'white',
						padding: 7,
						borderRadius: 3
					}}
				>
					<Grid container>
						<Grid item xs={12} sx={{ marginBottom: 4 }}>
							<Box>
								<Typography
									variant="h2Gascogne"
									sx={{
										color: primary.indigo,
										marginBottom: 1,
										display: 'block'
									}}
								>
									Loan Comparison Analyzer
								</Typography>
								<Typography
									variant="body2"
									sx={{ display: 'block' }}
								>
									Use this analytics tool to compare different
									loan options on your mortgage. Our software
									will auto populate available offers that we
									see on our platform for your mortgage.
									However, you can also input your own loan
									offers into the software.
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								sx={{
									height: '100%',
									width: '100%',
									display: 'flex',
									flexDirection: 'column'
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center'
									}}
								>
									{!isLiabilityDataEmpty ? (
										<Typography
											variant="subtitle1"
											sx={{ marginRight: 7 }}
										>
											Select Liabilities:
										</Typography>
									) : (
										<Typography variant="subtitle1">
											No Liabilities Found
										</Typography>
									)}
									{isSomeClientSelected &&
										clientLiabilitiesIsLoading && (
											<Skeleton
												sx={{ width: 300, height: 53 }}
											/>
										)}

									{isSomeClientSelected &&
										!clientLiabilitiesIsLoading &&
										!isLiabilityDataEmpty && (
											<Dropdown
												sx={{ width: 300 }}
												items={
													currentlySelectedClientLiabilitiesLabels
												}
												onChange={
													setSelectedLiabilityData
												}
												selected={selectedLiabilityData}
											/>
										)}
								</Box>
								<Box
									sx={{
										height: 89,
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<TertiaryButton
										sx={{ paddingTop: 2, paddingBottom: 2 }}
										onClick={requestOffer}
										isDisabled={
											!isSomeClientSelected ||
											selectedLiabilityData === '' ||
											clientLiabilitiesIsLoading ||
											selectedLiabilityData ===
												'No Liabilities Found'
										}
									>
										Generate Analyses
									</TertiaryButton>
								</Box>
							</Box>
						</Grid>

						<Grid item xs={6}>
							<Box
								sx={{
									maxWidth: '100%',
									height: '100%',
									padding: 2
								}}
							>
								{isAnalyticsOfferLoading && (
									<Skeleton
										variant="rectangular"
										sx={{ height: 350, width: '95%' }}
									/>
								)}
								{isAnalyticsOfferSuccess &&
									selectedLiabilityData !== '' && (
										<AnalyticsTable
											analyticsOffer={
												analyticsOfferTableData
											}
											bestOfferBalance={bestOfferBalance}
											assumptionsData={assumptionsData}
											setBestOfferBalance={
												setBestOfferBalance
											}
											bestOfferInterestRate={
												bestOfferInterestRate
											}
											setBestOfferInterestRate={
												setBestOfferInterestRate
											}
											bestOfferTenure={bestOfferTenure}
											setBestOfferTenure={
												setBestOfferTenure
											}
										/>
									)}
							</Box>
						</Grid>
					</Grid>

					<Grid item xs={12} sx={{ marginBottom: 4 }}>
						{isPageReady &&
							isAnalyticsSavingSuccess &&
							isAnalyticsNPVSuccess &&
							isAnalyticsUpsidePotentialSuccess && (
								<LoanComparisonTabs
									tabPanelSelected={tabPanelSelected}
									setTabPanelSelected={setTabPanelSelected}
									analyticsAmortizationData={
										analyticsAmortizationData
									}
									analyticsSavingData={analyticsSavingData}
									analyticsNPVData={analyticsNPVData}
									analyticsUpsidePotentialData={
										analyticsUpsidePotentialData
									}
								/>
							)}

						{(isAnalyticsSavingLoading ||
							isAnalyticsUpsidePotentialLoading ||
							isAnalyticsNPVLoading) && (
							<Skeleton
								variant="rectangle"
								height={350}
								sx={{ marginTop: 3 }}
							/>
						)}
					</Grid>
				</Box>

				{/* commented to address point 4 of ticket SORA2-723
				
				<Grid item xs={12}>
					{analyticsAmortizationIsLoading && (
						<Skeleton height={500} />
					)}
					{MemoizedAmortizationCard}
				</Grid> */}
			</>
		);
	}
}

export default LoanComparison;
