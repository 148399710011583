import { Box, Grid, Typography } from '@mui/material';
import { useParams, generatePath, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

// our components
import HomeWallet from 'components/SoraWallet/Wallets/HomeWallet';
import Loader from 'components/Loader';
import SoraWalletLoanCard from 'components/SoraWallet/SoraWalletLoanCard';
import LoanTypeAndCostOfDebtCard from 'components/SoraWallet/Cards/LoanTypeAndCostOfDebtCard';
import BalanceAndMonthyAmountCard from 'components/SoraWallet/Cards/BalanceAndMonthyAmountCard';
import InterestAndYearsRemainingCard from 'components/SoraWallet/Cards/InterestAndYearsRemainingCard';
import TotalCostOfDebtCard from 'components/SoraWallet/Cards/TotalCostOfDebtCard';
import WalletWellDoneCard from 'components/SoraWallet/Cards/WalletWellDoneCard';
import YearsRemainingCard from 'components/SoraWallet/Cards/YearsRemainingCard';
import ZeroLiabilities from 'components/SoraWallet/ZeroLiabilities';
import { TertiaryButton } from 'components/Button/Button';

// our Hooks
import useGetSoraWalletLiability from 'hooks/useGetSoraWalletLiability';

// Our utils
import { dataIsValid, extractSoraScore } from 'shared/utils';

// Constants
import { MORTGAGE, AUTO, STUDENT, PERSONAL, HELOC } from 'shared/constants';

// Routes
import {
	ADVISOR_DYNAMIC_UPDATE_LIABILITY_ROUTE,
	DYNAMIC_UPDATE_LIABILITY_ROUTE
} from 'routes';

function SoraWallet({ isClient }) {
	// isClient is undefined for FA and isClient is true for Clients
	const { clientId } = useParams();
	const { tradelineId } = useParams();
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	const isAdvisor = clientId !== undefined;

	const path = isAdvisor
		? ADVISOR_DYNAMIC_UPDATE_LIABILITY_ROUTE
		: DYNAMIC_UPDATE_LIABILITY_ROUTE;

	const {
		data: walletLiabilityData,
		isLoading,
		isSuccess,
		error
	} = useGetSoraWalletLiability(isClient);

	if (isLoading) {
		return (
			<Loader
				size={60}
				boxSX={{
					justifyContent: 'center',
					alignItems: 'center',
					height: 800,
					width: '100%'
				}}
			/>
		);
	}

	if (error) {
		return (
			<>
				<Helmet>
					<title>{isClient ? 'Client' : 'FA'} Wallet</title>
				</Helmet>
				<Box sx={{ height: '70vh', width: '100%', paddingTop: 20 }}>
					<Typography
						variant="h4"
						gutterBottom
						sx={{ marginBottom: 4 }}
					>
						Whoops. Something went wrong.
					</Typography>
					<Typography variant="subtitle1">
						The Sora team will fix it right away!
					</Typography>
				</Box>
			</>
		);
	}

	if (isSuccess) {
		const { bestRefi, currentLoan, owner, soraGrade } = walletLiabilityData;
		const tradeLineTypeName = currentLoan?.tradeLineType?.toLowerCase();
		const payload = isAdvisor
			? {
					clientId,
					tradelineId,
					tradelineType: tradeLineTypeName
			  }
			: {
					tradelineId,
					tradelineType: tradeLineTypeName
			  };
		const updateRoute = generatePath(path, payload);
		const isCurrentLoanMissing = !dataIsValid(currentLoan);

		if (isCurrentLoanMissing)
			return (
				<>
					<Helmet>
						<title>{isClient ? 'Client' : 'FA'} Wallet</title>
					</Helmet>
					<Box sx={{ height: '70vh', width: '100%', paddingTop: 20 }}>
						<Typography
							variant="h4"
							gutterBottom
							sx={{ marginBottom: 4 }}
						>
							Whoops. Something went wrong.
						</Typography>
						<Typography variant="subtitle1">
							The Sora team will fix it right away!
						</Typography>
					</Box>
				</>
			);

		// These values are received in cents from the backend, Dividing by 100 to format value before beginning to work with it.
		const untappedSavings =
			(walletLiabilityData?.untappedSaving ?? 0) / 100;
		const upsidePotential =
			(walletLiabilityData?.upsidePotential ?? 0) / 100;

		const { firstName, lastName } = owner;

		const contactClientData = {
			clientId,
			name: `${firstName} ${lastName}`
		};
		const formattedSoraGrade = extractSoraScore(soraGrade) ?? 'A';

		// Checks
		const noCurrentLoan = currentLoan === undefined;
		const hasAnExistingLoan = !noCurrentLoan;
		const hasNoOffers = bestRefi === undefined;
		const tradelineType = currentLoan.tradeLineType;
		const isHomeLiability = tradelineType === MORTGAGE;
		const isHelocLiability = tradelineType === HELOC;
		const isAutoLiability = tradelineType === AUTO;
		const isStudentLiability = tradelineType === STUDENT;
		const isPersonalLiability = tradelineType === PERSONAL;

		return (
			<>
				<Helmet>
					<title>{isClient ? 'Client' : 'FA'} Wallet</title>
				</Helmet>

				<Grid
					container
					spacing={2}
					sx={{ backgroundColor: primary.lightgrey, paddingRight: 6 }}
				>
					<Grid container item xs={12} sx={{ alignItems: 'center' }}>
						<Typography
							variant="h2Gascogne"
							sx={{
								color: SoraTheme.palette.primary.indigo,
								marginRight: 2
							}}
						>
							Loan Overview
						</Typography>

						<Link to={updateRoute} state={{ fromWallet: true }}>
							<TertiaryButton
								sx={{
									textTransform: 'none',
									minHeight: 40
								}}
							>
								Edit liability
							</TertiaryButton>
						</Link>
					</Grid>

					{noCurrentLoan && <ZeroLiabilities isClient={isClient} />}

					{/* HOME LIABILITY */}
					{hasAnExistingLoan && isHomeLiability && (
						<HomeWallet
							currentLoanDetails={currentLoan}
							contactClientData={contactClientData}
							homeDetails={currentLoan?.home}
							firstName={firstName}
							formattedSoraGrade={formattedSoraGrade}
							isClient={isClient}
							lastName={lastName}
							upsidePotential={upsidePotential}
							untappedSavings={untappedSavings}
							updateRoute={updateRoute}
							walletLiabilityData={walletLiabilityData}
						/>
					)}

					{/* AUTO, STUDENT & PERSONAL LIABILITY */}
					{hasAnExistingLoan &&
						(isAutoLiability ||
							isStudentLiability ||
							isPersonalLiability ||
							isHelocLiability) && (
							<Grid
								container
								item
								xs={12}
								rowSpacing={6}
								columnSpacing={3}
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-evenly'
								}}
							>
								<Grid item xs={12} md={4} xl={4}>
									<LoanTypeAndCostOfDebtCard
										currentLoanDetails={currentLoan}
										homeDetails={currentLoan?.home}
										firstName={firstName}
										lastName={lastName}
										lender={currentLoan?.lender}
										soraGrade={formattedSoraGrade}
									/>
								</Grid>

								<Grid item xs={12} md={4} xl={4}>
									<BalanceAndMonthyAmountCard
										currentLoanDetails={currentLoan}
									/>
								</Grid>
								<Grid item xs={12} md={4} xl={4}>
									<InterestAndYearsRemainingCard
										remainingMonth={
											currentLoan?.remainingMonth
										}
										currentLoanDetails={currentLoan}
									/>
								</Grid>

								<Grid container item xs={12} columnSpacing={3}>
									<Grid item xs={12}>
										<Typography
											variant="h2Gascogne"
											sx={{
												color: SoraTheme.palette.primary
													.indigo,
												display: 'block',
												marginBottom: 3
											}}
										>
											Sora Insights
										</Typography>
									</Grid>

									{hasNoOffers && (
										<Grid
											item
											xs={12}
											md={4}
											xl={4}
											sx={{ height: 195 }}
										>
											<WalletWellDoneCard />
										</Grid>
									)}
								</Grid>
							</Grid>
						)}
				</Grid>
			</>
		);
	}
}

SoraWallet.propTypes = {
	isClient: PropTypes.bool.isRequired
};

export default SoraWallet;
